<template>
  <vue-good-table
    id="staffTable"
    :columns="columns"
    :rows="rows"
    :sort-options="{ enabled: true }"
    :row-style-class="rowStyleClassFn"
    :pagination-options="{
      enabled: true,
      perPage: pageLength,
    }"
  >
    <div slot="emptystate" class="text-center">
      {{ $t('Global.Emptystate') }}
    </div>

    <template slot="table-row" slot-scope="props">
      <span v-if="props.column.field === 'staffScheduling.attendance.present'">
        <div
          class="present"
          :style="{
            backgroundColor:
              props.row.staffScheduling.attendance ===
              StaffSchedulingAttendance.available
                ? '#7dc134'
                : 'lightgrey',
            color:
              props.row.staffScheduling.attendance ===
              StaffSchedulingAttendance.available
                ? 'white'
                : 'inherit',
          }"
          @click="
            $emit(
              'change-attendance-to-available',
              props.row.staffScheduling.id,
            )
          "
        >
          {{ $t('ManageShifts.Present') }}
        </div>
      </span>
      <span
        v-else-if="props.column.field === 'staffScheduling.attendance.absent'"
      >
        <div
          class="absent"
          :style="{
            backgroundColor:
              props.row.staffScheduling.attendance ===
              StaffSchedulingAttendance.absent
                ? '#d43535'
                : 'lightgrey',
            color:
              props.row.staffScheduling.attendance ===
              StaffSchedulingAttendance.absent
                ? 'white'
                : 'inherit',
          }"
          @click="
            $emit('change-attendance-to-absent', props.row.staffScheduling.id)
          "
        >
          {{ $t('ManageShifts.Absent') }}
        </div>
      </span>
      <span v-else-if="props.column.field === 'staffScheduling.startTime'">
        <validation-provider
          #default="{ errors }"
          :name="`${$t('ManageShifts.StartTime')}`"
          style="width: 70px"
          :rules="{ required: true, regex: timeRegex }"
          class="mb-1"
        >
          <b-input-group class="d-flex">
            <b-form-input
              :id="`start-time`"
              v-model="props.row.staffScheduling.startTime"
              style="width: 70px"
              type="text"
              autocomplete="off"
              :state="errors.length > 0 ? false : null"
              placeholder="HH:mm"
              @input="
                $emit('change-time', {
                  key: 'startTime',
                  id: props.row.staffScheduling.id,
                  value: props.row.staffScheduling.startTime,
                })
              "
            />
            <b-input-group-append class="group-append">
              <b-form-timepicker
                v-model="props.row.staffScheduling.startTime"
                v-bind="$t('Bootstrap.TimePicker')"
                button-only
                :button-variant="
                  errors.length > 0 ? 'outline-danger' : 'outline-primary'
                "
                class="timepicker-height"
                @input="
                  $emit('change-time', {
                    key: 'startTime',
                    id: props.row.staffScheduling.id,
                    value: props.row.staffScheduling.startTime,
                  })
                "
              />
            </b-input-group-append>
          </b-input-group>
        </validation-provider>
      </span>
      <span v-else-if="props.column.field === 'staffScheduling.endTime'">
        <validation-provider
          #default="{ errors }"
          style="width: 70px"
          :name="`${$t('ManageShifts.EndTime')}`"
          :rules="{ required: true, regex: timeRegex }"
          class="mb-1"
        >
          <b-input-group class="d-flex">
            <b-form-input
              :id="`end-time`"
              v-model="props.row.staffScheduling.endTime"
              style="width: 70px"
              type="text"
              autocomplete="off"
              :state="errors.length > 0 ? false : null"
              placeholder="HH:mm"
              @input="
                $emit('change-time', {
                  key: 'endTime',
                  id: props.row.staffScheduling.id,
                  value: props.row.staffScheduling.endTime,
                })
              "
            />
            <b-input-group-append class="group-append">
              <b-form-timepicker
                v-model="props.row.staffScheduling.endTime"
                v-bind="$t('Bootstrap.TimePicker')"
                button-only
                :button-variant="
                  errors.length > 0 ? 'outline-danger' : 'outline-primary'
                "
                class="timepicker-height"
                @input="
                  $emit('change-time', {
                    key: 'endTime',
                    id: props.row.staffScheduling.id,
                    value: props.row.staffScheduling.endTime,
                  })
                "
              />
            </b-input-group-append>
          </b-input-group>
        </validation-provider>
      </span>
      <span v-else-if="props.column.field === 'employee.employeeNumber'">
        <div v-if="props.row.employee != undefined">
          {{ props.row.employee.employeeNumber }}
        </div>
        <div v-else>-</div>
      </span>
      <span v-else-if="props.column.field === 'serviceProvider.name'">
        <div v-if="props.row.serviceProvider != undefined">
          {{ props.row.serviceProvider.name }}
        </div>
        <div v-else>-</div>
      </span>
      <span v-else-if="props.column.field === 'staffScheduling.note'">
        <div
          v-b-modal.modal-open
          class="note"
          :style="{
            backgroundColor: checkString(props.row.staffScheduling.note)
              ? '#3185a1db'
              : 'lightgrey',
            color: checkString(props.row.staffScheduling.note)
              ? 'white'
              : 'inherit',
          }"
          @click="changeModalAcitivty(props.row.staffScheduling.id)"
        >
          {{ $t('ManageShifts.Note') }}
        </div>
        <div v-if="props.row.staffScheduling.id === rowId">
          <b-modal
            id="modal-open"
            ref="note-modal"
            size="lg"
            :title="props.row.staffScheduling.name"
            :ok-title="$t('Global.Save')"
            :cancel-title="$t('Global.Retreat')"
            cancel-variant="outline-secondary"
            @ok="handleOk"
          >
            <form ref="form" @submit.stop.prevent="handleSubmit">
              <b-form-group
                :state="textState"
                :label="$t('ManageShifts.Note')"
                label-size="lg"
                label-for="textarea"
              >
                <b-form-textarea
                  id="textarea"
                  v-model="noteText"
                  rows="12"
                  no-resize
                  :state="textState"
                  required
                />
              </b-form-group>
            </form>
          </b-modal>
        </div>
      </span>
    </template>

    <template slot="pagination-bottom" slot-scope="props">
      <div class="d-flex justify-content-between flex-wrap px-2 pb-2">
        <div class="d-flex align-items-center mb-0 mt-1 flex-wrap">
          <span class="text-nowrap">
            {{ $t('Global.ShowingOneOf') }}
          </span>
          <v-select
            v-model="pageLength"
            class="mx-1"
            style="min-width: 75px"
            :clearable="false"
            :options="['5', '10', '15']"
            @input="value => props.perPageChanged({ currentPerPage: value })"
          />
          <span class="text-nowrap">{{ $t('Global.EntriesShown') }}</span>
        </div>
        <div>
          <b-pagination
            :value="1"
            :total-rows="props.total"
            :per-page="pageLength"
            first-number
            last-number
            align="right"
            prev-class="prev-item"
            next-class="next-item"
            class="mt-1 mb-0"
            @input="value => props.pageChanged({ currentPage: value })"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </div>
      </div>
    </template>
  </vue-good-table>
</template>

<script>
import {
  BFormGroup,
  BModal,
  VBModal,
  BFormTextarea,
  BFormInput,
  BInputGroup,
  BFormTimepicker,
  BInputGroupAppend,
  BPagination,
} from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import { VueGoodTable } from 'vue-good-table'
import vSelect from 'vue-select'
import { required, regex } from '@validations'
import { timeRegex } from '@/utility/regex'
import StaffSchedulingAttendance from '../enums'

export default {
  components: {
    ValidationProvider,
    VueGoodTable,
    BFormTimepicker,
    vSelect,
    BPagination,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BFormTextarea,
    BModal,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    rows: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      required,
      regex,
      timeRegex,
      noteText: '',
      textState: null,
      rowId: 0,
      StaffSchedulingAttendance,
      pageLength: 5,
      columns: [
        {
          label: this.$t('ManageShifts.EmployeeNumber'),
          field: 'employee.employeeNumber',
          type: 'number',
          thClass: 'text-left',
          tdClass: 'text-left',
        },
        {
          label: this.$t('ManageShifts.Name'),
          field: 'staffScheduling.name',
        },
        {
          label: this.$t('ManageShifts.ServiceProvider'),
          field: 'serviceProvider.name',
        },
        {
          label: this.$t('ManageShifts.Present'),
          field: 'staffScheduling.attendance.present',
          sortable: false,
        },
        {
          label: this.$t('ManageShifts.Absent'),
          field: 'staffScheduling.attendance.absent',
          sortable: false,
        },
        {
          label: this.$t('ManageShifts.StartTime'),
          field: 'staffScheduling.startTime',
          sortable: false,
        },
        {
          label: this.$t('ManageShifts.EndTime'),
          field: 'staffScheduling.endTime',
          sortable: false,
        },
        {
          label: this.$t('ManageShifts.Note'),
          field: 'staffScheduling.note',
          sortable: false,
        },
      ],
    }
  },

  methods: {
    changeModalAcitivty(id) {
      this.rowId = id
      const row = this.rows.find(x => x.staffScheduling.id === id)

      if (!row) {
        return
      }

      if (row.staffScheduling.note !== undefined) {
        this.noteText = row.staffScheduling.note
      } else {
        this.noteText = ''
      }
    },

    handleOk() {
      if (this.noteText.length > 0) {
        this.$emit('text-for-note', {
          text: this.noteText,
          id: this.rowId,
        })
      } else {
        this.rows.forEach(row => {
          if (row.staffScheduling.id === this.rowId) {
            if (row.staffScheduling.note !== undefined) {
              this.$emit('text-for-note', {
                text: this.noteText,
                id: this.rowId,
              })
            }
          }
        })
      }
      this.noteText = ''
    },

    rowStyleClassFn(row) {
      if (row.staffScheduling.attendance !== undefined) {
        return row.staffScheduling.attendance === 'available' ? 'green' : 'red'
      }
      return null
    },

    checkString(value) {
      if (value === undefined || value === null) return false
      return value.length > 0
    },
  },
}
</script>

<style lang="scss" scoped>
.present {
  border-radius: 7px;
  width: 85%;
  height: 38px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.absent {
  position: center;
  border-radius: 7px;
  width: 85%;
  height: 38px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 300ms ease-in-out;
}

.note {
  position: center;
  border-radius: 7px;
  width: 100%;
  height: 38px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 300ms ease-in-out;
}

.group-append {
  height: 38px;
}

.timepicker-height {
  > button.btn {
    padding-top: 0 !important;
    padding-bottom: 0 !important;

    &[disabled='disabled'] {
      background-color: #efefef !important;
      border-color: #d8d6de !important;
      color: #6e6b7b !important;
    }
  }
}

.text-left {
  text-align: left;
}
</style>
