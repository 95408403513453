<template>
  <div v-if="carouselItems.length !== 1" class="container">
    <div class="control" @click="prevSlide">
      <span class="carousel-control-prev-icon" aria-hidden="true" />
      <span class="sr-only">Previous</span>
    </div>
    <div class="content">
      <div v-for="(elem, index) in carouselItems" :key="index" class="item">
        <Transition :name="back ? 'slide-back' : 'slide'">
          <div v-if="activeSlide === index" class="item">
            <div class="inner-content">
              <div class="top-item">
                {{ $t('ManageShifts.Area') }}
              </div>
              <div
                class="bottom-item"
                :class="{
                  [getContentSizeClass(elem.layerName)]: true,
                }"
              >
                {{ elem.layerName }}
              </div>
            </div>
          </div>
        </Transition>
      </div>
    </div>
    <div class="control" @click="nextSlide">
      <span class="carousel-control-next-icon" aria-hidden="true" />
      <span class="sr-only">Next</span>
    </div>
  </div>
  <div v-else class="content" style="border-radius: 5px">
    <div class="single-content">
      <div class="top-item">Bereich</div>
      <div
        class="bottom-single-item"
        :class="{
          [getContentSizeClass(carouselItems[0].layerName)]: true,
        }"
      >
        {{ carouselItems[0].layerName }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    carouselItems: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    back: false,
    activeSlide: 0,
    activeIndex: 0,
    slide: 0,
    sliding: null,
  }),

  methods: {
    prevSlide() {
      this.back = true
      this.activeSlide =
        (this.activeSlide - 1 + this.carouselItems.length) %
        this.carouselItems.length
      this.$emit('carousel-area-changed', this.activeSlide)
    },

    nextSlide() {
      this.back = false
      this.activeSlide = (this.activeSlide + 1) % this.carouselItems.length
      this.$emit('carousel-area-changed', this.activeSlide)
    },

    getContentSizeClass(name) {
      if (name.length < 10) {
        return 'content-small'
      }

      if (name.length < 22) {
        return 'content-medium'
      }

      return 'content-large'
    },
  },
}
</script>

<style lang="scss" scoped>
.single-content {
  background-color: white;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  flex-direction: column;
}

.inner-content {
  flex: 1;
  border-left: 0.4px solid rgba(0, 0, 0, 0.15);
  border-right: 0.4px solid rgba(0, 0, 0, 0.15);
}
.container {
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.15);
  display: flex;
  border-radius: 5px;
}
.control {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  width: 12%;
  cursor: pointer;
}
.content {
  margin-left: 0;
  background-color: white;
  width: 100%;
  height: 100%;
  flex: 1;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.item {
  position: absolute;
  display: flex;
  flex: 1;
  flex-direction: row;
  width: 100%;
  height: 100%;
}
.top-item {
  width: 100%;
  height: 40%;
  font-size: 1.15vw;
  font-weight: 420;
  color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
}
.bottom-single-item {
  margin-left: 10px;
  margin-left: 10px;

  width: 100%;
  height: 50%;

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;

  color: rgba(0, 0, 0, 0.5);

  font-weight: 450;
}

.bottom-item,
.bottom-single-item {
  word-break: break-word;

  &.content-small {
    font-size: clamp(14px, 2vw, 2rem);
  }

  &.content-medium {
    font-size: clamp(14px, 1vw, 2rem);
  }

  &.content-large {
    font-size: clamp(14px, 0.8vw, 2rem);
  }
}

.bottom-item {
  width: 100%;
  height: 50%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  font-weight: 450;
  color: rgba(0, 0, 0, 0.5);
}

.slide-leave-active,
.slide-enter-active {
  transition: 0.1s;
}
.slide-enter {
  transform: translate(100%, 0);
}
.slide-leave-to {
  transform: translate(-100%, 0);
}

.slide-back-leave-active,
.slide-back-enter-active {
  transition: 0.1s;
}
.slide-back-enter {
  transform: translate(-100%, 0);
}
.slide-back-leave-to {
  transform: translate(100%, 0);
}

.empty-orders {
  text-align: center;
  font-size: 1.4vw;
}
</style>
