<template>
  <b-overlay :show="loading" rounded="sm">
    <div>
      <b-row>
        <b-col md="3">
          <b-card
            class="header-content d-flex justify-content-between align-items-center"
            no-body
          >
            <Area
              :carousel-items="carouselItems"
              @carousel-area-changed="changeCarouselAreaIndex"
            />
          </b-card>
        </b-col>
        <b-col md="4">
          <b-card
            class="header-content d-flex justify-content-between align-items-center"
            no-body
          >
            <Shift :current-carousel-item="currentCarouselItem" />
          </b-card>
        </b-col>
        <b-col md="5">
          <b-card
            class="header-content d-flex justify-content-between align-items-center"
            no-body
          >
            <Instruction :current-lines="currentLines" />
          </b-card>
        </b-col>
      </b-row>
      <b-card no-body>
        <Table
          :rows="currentEmployees"
          @change-attendance-to-available="changeAttendanceToAvailable"
          @change-attendance-to-absent="changeAttendanceToAbsent"
          @text-for-note="textForNote"
          @change-time="changeTime"
        />
      </b-card>
      <Bottom
        :no-staff-available="noStaffAvailable"
        :carousel-items="carouselItems"
        @update-staff-scheduling-data="updateStaffSchedulingData"
      />
    </div>
  </b-overlay>
</template>

<script>
import { BCard, BRow, BCol, BOverlay } from 'bootstrap-vue'
import Shift from './components/shift.vue'
import Table from './components/table.vue'
import Area from './components/area.vue'
import Instruction from './components/instruction.vue'
import Bottom from './components/bottom.vue'
import { initialLoading, load } from './utility/load'
import StaffSchedulingAttendance from './enums'

export default {
  components: {
    Instruction,
    BCard,
    BRow,
    BCol,
    Shift,
    BOverlay,
    Table,
    Bottom,
    Area,
  },

  data: () => ({
    loading: false,
    activeCarouselAreaIndex: 0,
    carouselItems: [],
    currentLayerId: null,
    currentCarouselItem: {},
    currentEmployees: [],
    currentLines: [],
    activeCarouselIndex: 0,
    noStaffAvailable: true,
  }),

  async created() {
    await this.initialLoading()
    if (this.carouselItems.length > 0) {
      this.carouselItems.forEach(layer => {
        layer.employees.forEach(personnel => {
          if (layer.employees.length > 0) {
            this.noStaffAvailable = false
            if (personnel.staffScheduling.employee === null) {
              personnel.staffScheduling.name =
                personnel.staffScheduling.serviceProviderName
            } else {
              personnel.staffScheduling.name = `${personnel.employee.firstname} ${personnel.employee.lastname}`
            }
            if (personnel.staffScheduling.startTime === undefined) {
              personnel.staffScheduling.startTime = layer.startOfShift
              personnel.staffScheduling.endTime = layer.endOfShift
            }
          }
        })
      })
      this.currentLines = this.carouselItems[this.activeCarouselAreaIndex].lines
      this.currentLayerId =
        this.carouselItems[this.activeCarouselAreaIndex].layerId
      this.currentCarouselItem =
        this.carouselItems[this.activeCarouselAreaIndex]
      this.updateData()
    }
  },

  methods: {
    updateData() {
      this.currentEmployees = [
        ...this.carouselItems[this.activeCarouselAreaIndex].employees,
      ]
      this.currentCarouselItem = {
        ...this.carouselItems[this.activeCarouselAreaIndex],
      }
      this.currentLines = [
        ...this.carouselItems[this.activeCarouselAreaIndex].lines,
      ]
    },

    changeCarouselLineIndex(value) {
      this.activeCarouselAreaIndex = value
      this.currentCarouselItem =
        this.carouselItems[this.activeCarouselAreaIndex]
      this.updateData()
    },

    changeCarouselAreaIndex(value) {
      if (!this.carouselItems[this.activeCarouselAreaIndex]) return
      this.activeCarouselAreaIndex = value
      this.currentLayerId =
        this.carouselItems[this.activeCarouselAreaIndex].layerId
      this.currentCarouselItem =
        this.carouselItems[this.activeCarouselAreaIndex]
      this.updateData()
    },

    changeTime({ key, id, value }) {
      this.carouselItems.forEach(layer => {
        if (layer.id !== this.layerId) return

        layer.employees.forEach(employee => {
          if (employee.staffScheduling.id !== id) return
          employee.staffScheduling[key] = value.slice(0, 5)
        })
      })
      this.updateData()
    },

    changeAttendanceToAvailable(id) {
      this.carouselItems.forEach(layer => {
        if (layer.id === this.layerId) {
          layer.employees.forEach(employee => {
            if (employee.staffScheduling.id === id) {
              employee.staffScheduling.attendance =
                StaffSchedulingAttendance.available
            }
          })
        }
      })
      this.updateData()
    },

    changeAttendanceToAbsent(id) {
      this.carouselItems.forEach(layer => {
        if (layer.id === this.layerId) {
          layer.employees.forEach(employee => {
            if (employee.staffScheduling.id === id) {
              employee.staffScheduling.attendance =
                StaffSchedulingAttendance.absent
            }
          })
        }
      })
      this.updateData()
    },

    textForNote({ text, id }) {
      this.carouselItems.forEach(layer => {
        if (layer.id === this.layerId) {
          layer.employees.forEach(employee => {
            if (employee.staffScheduling.id === id) {
              employee.staffScheduling.note = text
            }
          })
        }
      })
      this.updateData()
    },

    async updateStaffSchedulingData() {
      const storedLayers = []
      let success = true
      await Promise.all(
        this.carouselItems.map(async layers => {
          if (!storedLayers.includes(layers.layerId)) {
            await Promise.all(
              layers.employees.map(async employee => {
                try {
                  await this.$axios.patch(
                    `staff-scheduling/${employee.staffScheduling.id}`,
                    {
                      attendance: employee.staffScheduling.attendance,
                      note: employee.staffScheduling.note,
                      startTime: employee.staffScheduling.startTime,
                      endTime: employee.staffScheduling.endTime,
                    },
                  )
                } catch {
                  success = false
                  this.$alert.error()
                }
              }),
            )
          }
          storedLayers.push(layers.layerId)
        }),
      )
      if (success) {
        const subject = this.$t('ManageShifts.ManageShifts')
        this.$alert.success(this.$t('Global.SuccessUpdate', { subject }))
      }
    },

    async initialLoading() {
      return initialLoading.call(this)
    },

    async load(...args) {
      return load.call(this, ...args)
    },
  },
}
</script>

<style lang="scss">
.header-content {
  display: flex;
  justify-content: flex-start;
  height: 11.5vh;
}
</style>
