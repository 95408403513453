<template>
  <div class="container">
    <div class="control">
      <div
        v-if="currentLines.length > 1"
        class="cursor-pointer"
        @click="prevSlide"
      >
        <span class="carousel-control-prev-icon" aria-hidden="true" />
        <span class="sr-only">Previous</span>
      </div>
    </div>
    <div class="content">
      <div v-for="(elem, index) in currentLines" :key="index" class="item">
        <Transition :name="back ? 'slide-back' : 'slide'">
          <div v-if="activeSlide === index" class="item">
            <div class="inner-content">
              <div class="top-item">
                {{ $t('ManageShifts.Line') }}
              </div>
              <div
                class="bottom-item"
                :class="{
                  [getContentSizeClass(elem.lineName)]: true,
                }"
              >
                {{ elem.lineName }}
              </div>
            </div>
            <div class="inner-content">
              <div class="top-item">{{ $t('ManageShifts.Need') }}</div>
              <div
                class="bottom-item"
                :class="{
                  [getContentSizeClass(String(elem.orders.needSumOfLine))]:
                    true,
                }"
              >
                {{ elem.orders.needSumOfLine }}
              </div>
            </div>
            <div class="inner-content">
              <div class="top-item">{{ $t('ManageShifts.OrdersName') }}</div>
              <div
                v-if="elem.orders.ordersName.length > 0"
                class="bottom-item-orders"
                :class="{
                  [getContentSizeClass(elem.orders.ordersName.join('; '))]:
                    true,
                }"
              >
                <div
                  v-for="(orderName, indexOfName) in elem.orders.ordersName"
                  :key="indexOfName"
                >
                  {{ orderName }};
                </div>
              </div>
              <div v-else class="empty-orders">-</div>
            </div>
          </div>
        </Transition>
      </div>
    </div>
    <div class="control">
      <div
        v-if="currentLines.length > 1"
        class="cursor-pointer"
        @click="nextSlide"
      >
        <span class="carousel-control-next-icon" aria-hidden="true" />
        <span class="sr-only">Next</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    currentLines: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      back: false,
      activeSlide: 0,
      activeIndex: 0,
      slide: 0,
      sliding: null,
    }
  },

  watch: {
    currentLines() {
      this.activeSlide = 0
    },
  },

  methods: {
    getContentSizeClass(name) {
      if (name.length < 10) {
        return 'content-small'
      }

      if (name.length < 22) {
        return 'content-medium'
      }

      return 'content-large'
    },

    prevSlide() {
      this.back = true
      this.activeSlide =
        (this.activeSlide - 1 + this.currentLines.length) %
        this.currentLines.length
    },

    nextSlide() {
      this.back = false
      this.activeSlide = (this.activeSlide + 1) % this.currentLines.length
    },
  },
}
</script>

<style lang="scss" scoped>
.inner-content {
  flex: 1;
  border-left: 0.4px solid rgba(0, 0, 0, 0.15);
  border-right: 0.4px solid rgba(0, 0, 0, 0.15);
}
.container {
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.15);
  display: flex;
  border-radius: 5px;
}

.control {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  width: 8%;
}

.content {
  margin-left: 0;
  background-color: white;
  width: 100%;
  height: 100%;
  flex: 1;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.item {
  position: absolute;
  display: flex;
  flex: 1;
  flex-direction: row;
  width: 100%;
  height: 100%;
}

.top-item {
  margin-top: 0.5vh;
  width: 100%;
  height: 40%;
  font-size: 1.15vw;
  font-weight: 420;
  color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
}

.bottom-item {
  width: 100%;
  height: 50%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  font-weight: 450;
  color: rgba(0, 0, 0, 0.5);

  text-align: center;
}

.bottom-item-orders {
  width: 100%;
  height: 50%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  font-weight: 450;
  color: rgba(0, 0, 0, 0.6);
}

.bottom-item,
.bottom-item-orders {
  word-break: break-word;

  padding-left: 10px;
  padding-right: 10px;

  &.content-small {
    font-size: clamp(14px, 1.4vw, 2rem);
  }

  &.content-medium {
    font-size: clamp(14px, 0.8vw, 2rem);
  }

  &.content-large {
    font-size: clamp(14px, 0.6vw, 2rem);
  }
}

.slide-leave-active,
.slide-enter-active {
  transition: 0.1s;
}
.slide-enter {
  transform: translate(100%, 0);
}
.slide-leave-to {
  transform: translate(-100%, 0);
}

.slide-back-leave-active,
.slide-back-enter-active {
  transition: 0.1s;
}
.slide-back-enter {
  transform: translate(-100%, 0);
}
.slide-back-leave-to {
  transform: translate(100%, 0);
}

.empty-orders {
  text-align: center;
  font-size: 1.4vw;
}
</style>
