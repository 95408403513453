export async function load(key, endpoint = key) {
  try {
    const { data } = await this.$axios.get(endpoint)
    this[key] = data
    return data
  } catch (error) {
    this.$alert.error()
    return error
  }
}

export async function initialLoading() {
  this.loading = true
  await Promise.all([
    this.load('carouselItems', 'manage-shifts/my-current-responsibilities'),
  ])
  this.loading = false
}
