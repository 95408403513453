<template>
  <div class="content">
    <div v-if="Object.keys(currentCarouselItem).length > 0" class="contents">
      <div
        :style="{
          backgroundColor: currentCarouselItem.groupColor,
          color: getContrastColor(currentCarouselItem.groupColor),
        }"
        :class="`group-classifier ${getContentSizeClass(
          currentCarouselItem.groupName,
        )}`"
      >
        {{ currentCarouselItem.groupName }}
      </div>
      <div class="shift-data">
        {{ currentCarouselItem.shiftName }}
        <div class="date-span">
          <span>
            {{ currentCarouselItem.daySpan.start }}
            {{ currentCarouselItem.startOfShift }}
          </span>
          <span class="space-x-y">-</span>
          <span>
            {{ currentCarouselItem.daySpan.end }}
            {{ currentCarouselItem.endOfShift }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    currentCarouselItem: {
      type: Object,
      required: true,
    },
  },

  methods: {
    getContentSizeClass(name) {
      if (name.length < 3) {
        return 'content-small'
      }

      if (name.length < 10) {
        return 'content-medium'
      }

      return 'content-large'
    },

    getContrastColor(color) {
      color = color.replace('#', '')
      const r = parseInt(color.substring(0, 2), 16) / 255
      const g = parseInt(color.substring(2, 4), 16) / 255
      const b = parseInt(color.substring(4, 6), 16) / 255
      const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b
      return luminance > 0.8 ? '#000000' : '#FFFFFF'
    },
  },
}
</script>

<style lang="scss" scoped>
.content {
  width: 100%;
  height: 100%;
  display: flex;
  margin-left: 0;

  .contents {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;

    .group-classifier {
      width: 25%;
      min-width: 120px;

      display: flex;
      align-items: center;
      justify-content: center;

      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;

      text-align: center;
      word-wrap: break-word;

      padding-left: 10px;
      padding-right: 10px;

      font-weight: 350;
      &.content-small {
        font-size: 3vw;
      }

      &.content-medium {
        font-size: 1.4rem;
      }

      &.content-large {
        font-size: 100%;
      }
    }

    .shift-data {
      width: 75%;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      color: rgba(0, 0, 0, 0.5);

      font-size: clamp(18px, 1.6vw, 2rem);
      font-weight: 300;

      border-left: 0.8px solid rgba(0, 0, 0, 0.25);

      .date-span {
        font-size: clamp(16px, 1.5vw, 2rem);
        font-weight: 250;

        justify-content: center;
        align-items: center;

        display: flex;
        flex-wrap: wrap;

        .space-x-y {
          padding-left: 4px;
          padding-right: 4px;
        }
      }
    }
  }
}
</style>
