<template>
  <b-row class="mt-2 d-flex">
    <b-col class="d-flex justify-content-end">
      <b-button
        v-if="carouselItems.length > 0"
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        class="mr-2"
        variant="outline-secondary"
        @click="print"
      >
        {{ $t('ManageShifts.PrintEmployeeList') }}
      </b-button>

      <b-button
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        v-b-modal.modal-danger
        class="mr-2"
        variant="primary"
        :disabled="noStaffAvailable"
        @click="checkUpdate"
      >
        {{ $t('ManageShifts.Transmit') }}
      </b-button>
    </b-col>
    <div v-if="openModal">
      <b-modal
        id="modal-danger"
        ok-only
        ok-variant="danger"
        :ok-title="$t('Global.Ok')"
        modal-class="modal-danger"
        centered
        :title="$t('ManageShifts.ErrorOccurred')"
      >
        <b-card-text>
          {{ $t('ManageShifts.CheckEmployeesData') }}
        </b-card-text>
      </b-modal>
    </div>
  </b-row>
</template>

<script>
import { BRow, BCol, BButton, BModal, VBModal, BCardText } from 'bootstrap-vue'
import { timeRegex } from '@/utility/regex'

export default {
  components: {
    BButton,
    BModal,
    BRow,
    BCol,
    BCardText,
  },

  directives: {
    'b-modal': VBModal,
  },

  props: {
    carouselItems: {
      type: Array,
      default: () => [],
    },
    noStaffAvailable: {
      type: Boolean,
      default: () => true,
    },
  },

  data: () => ({
    openModal: false,
  }),

  methods: {
    canUpdate() {
      if (this.carouselItems.length === 0) return false
      return !this.carouselItems.some(layers =>
        layers.employees.some(
          employee =>
            employee.staffScheduling.attendance === undefined ||
            !timeRegex.test(employee.staffScheduling.startTime) ||
            !timeRegex.test(employee.staffScheduling.endTime),
        ),
      )
    },

    checkUpdate() {
      this.openModal = false
      if (this.canUpdate()) {
        this.$emit('update-staff-scheduling-data')
        return
      }
      this.openModal = true
    },

    print() {
      this.$router.push({ name: 'manage-shifts-print' })
    },
  },
}
</script>
